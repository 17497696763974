@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);
html {
    /*font-size: 14px !important;*/
}

* {
    font-family: 'Noto Sans KR', sans-serif !important;
}

body {
    background-color: #F8F9FC !important;
}

i.icon {
    font-family: Icons !important;
}

.mainDescriptionGrid {
    min-height: 80vh;
    text-align: center;
}

.mainDescriptionGrid.TwoColumn {
    min-height: 40vh;
    text-align: center;
}

.mainDescriptionGrid.FourColumn {
    min-height: 20vh;
    text-align: center;
}

.landingFooter {
    background-color: #F8F8F8;
    padding-bottom: 3rem;
    margin-top: 2.6rem;
}

.mainHeaderText {
    font-weight: 900;
    font-size: 2rem;
    line-height: 2.5rem;
}

.subHeaderText {
    font-weight: 200;
    margin-top: 0.3rem;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #363636;
}

.mainDescriptionText {
    margin-top: 1rem;
    font-weight: 900;
    font-size: 1.7rem;
    line-height: 2.5rem;
    color: #363636;
}

.detailDescriptionText {
    margin-top: 1rem;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2rem;
    color: #363636;
}

p.buttonHover:hover {
    font-weight: 900;
    cursor: pointer;
}

.roundButton {
    padding: 1rem 2rem;
    margin: auto;
    font-weight: 900;
    font-size: 1.3rem;
    border-radius: 4rem;
    border: 1px solid transparent;
    background-color: #69FF46;
    color: white;
}

.roundButton:hover {
    font-weight: 900;
    cursor: pointer;
    border: 1px solid #69FF46;
    background-color: transparent;
    color: #69FF46;
}

body {
    margin: 0;

    /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
    /*"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
    /*sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.mainImage {
    width: 50vw;
}

.curationImage {
    width: 70%;
    align-self: flex-end;
}

@media only screen and (max-width: 767px) {
    .ui.stackable.grid > .row > .column.noMargin {
        /*padding-left: 0!important;*/
        /*padding-right: 0!important;*/
        padding: 0rem 0rem !important;
    }
}

@media (max-width: 600px) {
    html {
        font-size: 12px !important;
        word-break: keep-all;
    }

    .mainImage {
        width: 100%;
    }

    .curationImage {
        width: 100%;
    }

    #noMargin {
        /*padding-left: 0!important;*/
        /*padding-right: 0!important;*/
        padding: 0rem 0rem !important;

    }
}
